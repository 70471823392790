<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <li
            class="nav-item mr-auto d-flex align-items-center"
            v-if="isVerticalMenuCollapsed"
          >
            <b-link class="navbar-brand" to="/dashboard">
              <span class="brand-logo">
                <b-img src="@/assets/images/logo/favicon.png" alt="logo" />
                <!-- <b-img v-if="skin === 'light'" :src="collapsedLogo" alt="logo" />
                <b-img v-else :src="collapsedLogoDark" alt="logo"/> -->
              </span>
            </b-link>
          </li>
          <!-- Logo & Text -->
          <li v-else class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/dashboard">
              <span class="brand-logo">
                <b-img v-if="skin === 'light'" :src="appLogoImage" alt="logo" />
                <b-img v-else :src="appDarkLogoImage" alt="logo" />
              </span>
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <vertical-nav-menu-items
        :items="navMenuItems1"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from "@/navigation/vertical";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg } from "bootstrap-vue";
import { provide, computed, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  data() {
    return {
      // isShow: process.env.VUE_APP_SHOW_ITEM,
      navMenuItems1: [
        {
          title: "Home",
          route: "v1-dashboard",
          icon: "HomeIcon",
          is_allowed: "true",
          is_show: true,
          // is_allowed: !this.$store.state.app.user.is_staff,
        },
        {
          title: "Quarterly Dashboard",
          route: "quarterly-dashboard",
          icon: "PieChartIcon",
          is_allowed: "true",
          is_show: true,
          // is_allowed: !this.$store.state.app.user.is_staff,
        },
        {
          title: "Vulnerabilities",
          route: "All Vulnerabilities",
          icon: "AlertTriangleIcon",
          is_show: true,
          is_allowed: this.$store.state.app.user.permissions.includes(
            "asset.list_mongo_vulnerabilities"
          ),
        },
        // {
        //   title: "Penetration Testing old",
        //   route: {
        //     name: "PT Vulnerabilities",
        //     query: { exploited: true }, // Add your query parameter here
        //   },
        //   icon: "ShieldIcon",
        //   is_show: true,
        //   is_allowed: this.$store.state.app.user.permissions.includes(
        //     "asset.list_mongo_vulnerabilities"
        //   ),
        // },
        // tration Testing
        {
          title: "Penetration Testing",
          route: "PT Vulnerabilities",
          icon: "ShieldIcon",
          is_show: true,
          is_allowed: this.$store.state.app.user.permissions.includes(
            "asset.list_mongo_vulnerabilities"
          ),
          tag: "Plus",
          tagVariant: "success",
        },

        {
          title: "Attack Surface",
          route: "Attack-Surface",
          icon: "CrosshairIcon",
          is_show: true,
          is_allowed: this.$store.state.app.user.permissions.includes(
            "asset.list_all_org_assets"
          ),
        },
        {
          title: "Assets",
          icon: "GridIcon",
          is_show: true,
          is_allowed:
            this.$store.state.app.user.permissions.includes(
              "asset.add_assettype"
            ) ||
            this.$store.state.app.user.permissions.includes(
              "asset.list_assetgroups"
            ) ||
            this.$store.state.app.user.permissions.includes(
              "asset.list_all_org_assets"
            ) ||
            this.$store.state.app.user.permissions.includes(
              "asset.add_organizationasset"
            ) ||
            this.$store.state.app.user.permissions.includes(
              "asset.list_org_assets"
            ),
          children: [
            {
              title: "Asset Types",
              route: "AssetTypes",
              is_show: true,
              is_allowed: this.$store.state.app.user.permissions.includes(
                "asset.add_assettype"
              ),
            },
            {
              title: "Asset Groups",
              route: "Groups",
              is_show: true,
              is_allowed: this.$store.state.app.user.permissions.includes(
                "asset.list_assetgroups"
              ),
            },
            {
              title: "All Assets",
              route: "Assets",
              is_show: true,
              is_allowed:
                this.$store.state.app.user.permissions.includes(
                  "asset.list_all_org_assets"
                ) ||
                this.$store.state.app.user.permissions.includes(
                  "asset.list_org_assets"
                ),
            },
            // {
            //   title: "Emails",
            //   route: "Email Harvest",
            //   // is_allowed: true
            //   is_allowed:this.$store.state.app.user.permissions.includes("scan.list_scan"),
            // },
            {
              title: "Discovery",
              route: "Discovery List",
              is_show: true,
              // is_allowed: true
              is_allowed:
                this.$store.state.app.user.permissions.includes(
                  "scan.list_scan"
                ),
            },

            {
              title: "Add Assets",
              route: "Add Asset",
              icon: "PlusIcon",
              is_show: true,
              is_allowed: this.$store.state.app.user.permissions.includes(
                "asset.add_organizationasset"
              ),
            },
          ],
        },
        {
          title: "Baseline Audits",
          icon: "FileTextIcon",
          is_show: true,
          is_allowed: "true",
          children: [
            {
              title: "Cloud Configuration",
              route: "Cloud-Configuration",
              is_show: true,
              icon: "CloudIcon",
              is_allowed: "true",
            },
            {
              title: "Code Review",
              route: "Code-Review",
              is_show: true,
              icon: "CodeIcon",
              is_allowed: "true",
            },
          ],
        },
        {
          title: "Scans",
          icon: "SearchIcon",
          is_show: true,
          is_allowed:
            this.$store.state.app.user.permissions.includes(
              "scan.list_scanschedule"
            ) ||
            this.$store.state.app.user.permissions.includes("scan.list_scan"),
          children: [
            {
              title: "Schedules",
              route: "List Schedules",
              is_show: true,
              icon: "ClockIcon",
              is_allowed: this.$store.state.app.user.permissions.includes(
                "scan.list_scanschedule"
              ),
            },
            {
              title: "Scans",
              route: "List Scans",
              icon: "SearchIcon",
              is_show: true,
              is_allowed:
                this.$store.state.app.user.permissions.includes(
                  "scan.list_scan"
                ),
            },
            // {
            //   title: "Domain Discovery",
            //   route: "Asset Discovery",
            //   icon: "SearchIcon",
            //   is_allowed:
            //     this.$store.state.app.user.permissions.includes("assetDiscovery.list_assetdiscovery"),
            // },
          ],
        },
        {
          title: "Reports",
          icon: "BookIcon",
          is_show: true,
          is_allowed:
            this.$store.state.app.user.permissions.includes(
              "report.list_all_asset_reports"
            ) ||
            this.$store.state.app.user.permissions.includes(
              "report.list_all_templates"
            ),
          children: [
            {
              title: "Templates",
              route: "List Templates",
              icon: "CircleIcon",
              is_show: true,
              is_allowed: this.$store.state.app.user.permissions.includes(
                "report.list_all_templates"
              ),
              // icon: 'PackageIcon',
            },
            {
              title: "Reports",
              route: "List Reports",
              icon: "CircleIcon",
              is_show: true,
              is_allowed: this.$store.state.app.user.permissions.includes(
                "report.list_all_asset_reports"
              ),
              // icon: 'PackageIcon',
            },
            {
              title: "Scheduled Reports",
              route: "List Scheduled Reports",
              icon: "CircleIcon",
              is_show: true,
              is_allowed: this.$store.state.app.user.permissions.includes(
                "report.list_all_asset_reports"
              ),
              // icon: 'PackageIcon',
            },
          ],
        },
        {
          title: "Tasks",
          route: "task",
          icon: "EditIcon",
          is_allowed: "true",
          is_show: true,
          // is_allowed: !this.$store.state.app.user.is_staff,
        },
        // {
        //   title: "Tasks-Stable",
        //   route: "task-stable",
        //   icon: "EditIcon",
        //   is_allowed: "true",
        // },
        {
          title: "Users",
          route: "Users",
          icon: "UserIcon",
          is_show: true,
          is_allowed:
            this.$store.state.app.user.permissions.includes("user.list_users"),
        },
        {
          title: "Teams",
          route: "Teams",
          icon: "UsersIcon",
          is_allowed:
            this.$store.state.app.user.permissions.includes("user.list_teams"),
          is_show: true,
        },

        {
          title: "Network Location",
          route: "Network-Location",
          icon: "GlobeIcon",
          is_show: true,
          is_allowed: "true",
        },

        // {
        //   title: "Asset Discovery",
        //   route: "Asset Discovery",
        //   icon: "SearchIcon",
        //   is_allowed:
        //     this.$store.state.app.user.permissions.includes("assetDiscovery.list_assetdiscovery"),
        // },
        // {
        //   title: 'Vulnerability Database',
        //   route: 'cvd',
        //   icon: 'DatabaseIcon',
        //   is_allowed: this.$store.state.app.user.is_staff
        // },

        // {
        //   title: "Assets",
        //   icon: "BriefcaseIcon",
        //   is_allowed: !this.$store.state.app.user.is_staff,
        //   children: [
        //     {
        //       title: "Asset Groups",
        //       route: "Groups",
        //       is_allowed: this.$store.state.app.user.permissions.includes(
        //         "asset.list_assetgroups"
        //       ),
        //     },
        //     {
        //       title: "Assets",
        //       route: "Assets",
        //       is_allowed: this.$store.state.app.user.permissions.includes(
        //         "asset.list_all_org_assets"
        //       ),
        //       //icon: 'BriefcaseIcon',
        //     },
        //     {
        //       title: "Add Assets",
        //       route: "Add Asset",
        //       icon: "PlusIcon",
        //       is_allowed: this.$store.state.app.user.permissions.includes(
        //         "asset.add_organizationasset"
        //       ),
        //     },
        //   ],
        // },

        // ------------------commented project section-------------//

        // {
        //   title: "Projects",
        //   icon: "ClipboardIcon",
        //   is_show: this.$store.state.app.user.is_staff,
        //   // is_allowed: "true",
        //   is_allowed:
        //     this.$store.state.app.user.permissions.includes(
        //       "project.list_all_projects"
        //     ) ||
        //     this.$store.state.app.user.permissions.includes(
        //       "project.list_all_activities"
        //     ),
        //   children: [
        //     {
        //       title: "Projects",
        //       route: "List Projects",
        //       icon: "CircleIcon",
        //       is_show: this.$store.state.app.user.is_staff,
        //       is_allowed: this.$store.state.app.user.permissions.includes(
        //         "project.list_all_projects"
        //       ),
        //     },
        //     {
        //       title: "Activity",
        //       route: "List Activity",
        //       icon: "CircleIcon",
        //       is_show: this.$store.state.app.user.is_staff,
        //       is_allowed: this.$store.state.app.user.permissions.includes(
        //         "project.list_all_activities"
        //       ),
        //     },
        //   ],
        // },
        { header: "Admin", is_allowed: true, is_show: true },
        {
          title: "Roles",
          route: "Roles",
          icon: "AwardIcon",
          is_show: true,
          is_allowed:
            this.$store.state.app.user.permissions.includes("auth.view_group"),
        },
        {
          title: "Organizations",
          route: "Organizations",
          icon: "FolderIcon",
          is_show: true,
          is_allowed: this.$store.state.app.user.permissions.includes(
            "organization.list_all_organizations"
          ),
        },
        {
          title: "Scanner Settings",
          icon: "BoxIcon",
          is_show: this.$store.state.app.user.is_staff,
          // is_allowed: "true",
          is_allowed:
            this.$store.state.app.user.permissions.includes(
              "agent.list_agent"
            ) ||
            this.$store.state.app.user.permissions.includes(
              "scan.list_nessus_policy"
            ),
          children: [
            {
              title: "Scanners",
              route: "Scanners",
              icon: "CircleIcon",
              is_show: true,
              is_allowed:
                this.$store.state.app.user.permissions.includes(
                  "agent.list_agent"
                ),
            },
            {
              title: "Nessus Profile",
              route: "Nessus Profile",
              icon: "CircleIcon",
              is_show: true,
              is_allowed: this.$store.state.app.user.permissions.includes(
                "scan.list_nessus_policy"
              ),
            },
          ],
        },
        {
          title: "Agent",
          route: "Agent List",
          icon: "TriangleIcon",
          is_show: true,
          is_allowed:
            this.$store.state.app.user.permissions.includes("agent.list_agent"),
        },
        {
          title: "Features",
          route: "Feature List",
          icon: "StarIcon",
          is_show: true,
          is_allowed: this.$store.state.app.user.is_staff,
          // is_allowed:true,
        },
        {
          title: "Insecure Port",
          route: "Insecure-port List",
          icon: "MapPinIcon",
          is_show: true,
          is_allowed: this.$store.state.app.user.permissions.includes(
            "asset.list_insecureport"
          ),
        },

        // ############################
        {
          title: "Logs",
          route: "Logs",
          icon: "FileIcon",
          is_show: true,
          is_allowed:
            // this.$store.state.app.user.groups[0].name == "Admin",
            this.$store.state.app.user.permissions.includes(
              "logs.list_user_log"
            ),
        },
        {
          title: "Deleted Items",
          icon: "TrashIcon",
          is_show: true,
          is_allowed: true,
          children: [
            {
              title: "Organization",
              route: "organization-delete-list",
              icon: "CircleIcon",
              is_show: true,
              // is_allowed: "true",
              // icon: 'PackageIcon',
              is_allowed: this.$store.state.app.user.permissions.includes(
                "organization.delete_organization"
              ),
            },
            {
              title: "Users",
              route: "user-delete-list",
              icon: "CircleIcon",
              is_show: true,
              // is_allowed: "true",
              is_allowed:
                this.$store.state.app.user.permissions.includes(
                  "user.delete_user"
                ),
            },
            {
              title: "Assets",
              route: "asset-delete-list",
              icon: "CircleIcon",
              is_show: true,
              // is_allowed: "true",
              is_allowed: this.$store.state.app.user.permissions.includes(
                "asset.delete_any_org_asset"
              ),
            },
            {
              title: "Asset Groups",
              route: "asset-group-delete-list",
              icon: "CircleIcon",
              is_show: true,
              // is_allowed: "true",
              is_allowed: this.$store.state.app.user.permissions.includes(
                "asset.delete_assetgroup"
              ),
            },
            {
              title: "Vulnerabilities",
              route: "vulnerabilities-delete-list",
              icon: "CircleIcon",
              is_show: true,
              // is_allowed: "true",
              is_allowed: this.$store.state.app.user.permissions.includes(
                "asset.delete_assetvulnerability"
              ),
            },
          ],
        },

        // {
        //   title: 'Reports',
        //   route: 'List Reports',
        //   icon: 'BookIcon',
        //   is_allowed:"true"
        // },
      ],
      collapsedLogo: require("@/assets/images/logo/favicon.png"),
    };
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
    );

    // App Name
    const { appName, appLogoImage, appDarkLogoImage } = $themeConfig.app;

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      appDarkLogoImage,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
